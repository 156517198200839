var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { SystemLogService } from "~/services/manage-service/system-log.service";
// @Auth(858)
var LoginLog = /** @class */ (function (_super) {
    __extends(LoginLog, _super);
    function LoginLog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectionList = [];
        _this.loading = {
            state: false
        };
        _this.loginLogModel = {
            realName: "",
            ip: "",
            username: "",
            loginTime: [],
            type: "",
            isSuccess: "",
        };
        _this.dataSet = [];
        return _this;
    }
    LoginLog.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 刷新
     */
    LoginLog.prototype.refreshData = function () {
        var _this = this;
        this.systemLogService.findAllLoginLog(this.loginLogModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    LoginLog.prototype.onBatchDel = function () {
        var _this = this;
        if (this.selectionList.length === 0) {
            this.$alert("请选择要删除的登录日志", "提示", {
                confirmButtonText: "确定"
            });
        }
        else {
            var ids_1 = [];
            this.selectionList.map(function (e) { return ids_1.push(e.id); });
            this.systemLogService.batchDeleteLog(ids_1, this.loading).subscribe(function (data) {
                _this.$message.success("删除成功!");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        }
    };
    __decorate([
        Dependencies(SystemLogService)
    ], LoginLog.prototype, "systemLogService", void 0);
    __decorate([
        Dependencies(PageService)
    ], LoginLog.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], LoginLog.prototype, "sortService", void 0);
    LoginLog = __decorate([
        Layout('workspace'),
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm
            }
        })
    ], LoginLog);
    return LoginLog;
}(Vue));
export default LoginLog;
